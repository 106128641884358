import { createSlice } from '@reduxjs/toolkit'
import {
	getAllGuides,
	getAllGuidesBySearch,
	getGuidesById,
	createGuide,
	updateGuide,
	createDateForGuide,
	updateDateById,
	deleteGuideById
} from 'store/actions/GuidesActions'
import { IDate, IGuide, IGuidesState } from 'types/guides';




const initialState: IGuidesState = {
	guides: [],
	guide: null,
	tours: null,
	status: "loading",
};

export const guidesSlice = createSlice({
  name: 'guides',
  initialState,
	reducers: {
		clearGuide: (state) => {
      state.guide = initialState.guide
    },
		setGuide: (state, action) => {
      state.guide = action.payload
    },
	},
	extraReducers: (builder) => {
    // get all guides
    builder.addCase(getAllGuides.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(getAllGuides.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = action.payload;
			}
      state.status = "succesed";
    })
    builder.addCase(getAllGuides.rejected, (state) => {
      state.guides = initialState.guides
      state.status = "failed";
    })
		// search guides
		builder.addCase(getAllGuidesBySearch.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllGuidesBySearch.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = action.payload;
			}
			state.status = "succesed";
		})
		builder.addCase(getAllGuidesBySearch.rejected, (state) => {
			state.guides = initialState.guides
			state.status = "failed";
		})
		// get guide by id
		builder.addCase(getGuidesById.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getGuidesById.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guide = action.payload.guide;
				state.tours = action.payload.tours;
			}
			state.status = "succesed";
		})
		builder.addCase(getGuidesById.rejected, (state) => {
			state.guide = initialState.guide
			state.tours = initialState.tours
			state.status = "failed";
		})
		// create guide
		builder.addCase(createGuide.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createGuide.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = state.guides ? [...state.guides, action.payload] : [action.payload]
			}
			state.status = "succesed";
		})
		builder.addCase(createGuide.rejected, (state) => {
			state.status = "failed";
		})
		// update guide
		builder.addCase(updateGuide.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(updateGuide.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = state.guides?.map((guide: IGuide) => {
					if (guide?.id === action?.payload?.id) {
						return action.payload
					} else {
						return guide
					}
				}) as IGuide[]
			}
			state.status = "succesed";
		})
		builder.addCase(updateGuide.rejected, (state) => {
			state.status = "failed";
		})
		// delete guide
		builder.addCase(deleteGuideById.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteGuideById.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = state.guides?.filter((guide: IGuide) =>
				guide?.id !== action?.payload
				) as IGuide[]
			}
			state.status = "succesed";
		})
		builder.addCase(deleteGuideById.rejected, (state) => {
			state.status = "failed";
		})
		// create date
		builder.addCase(createDateForGuide.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createDateForGuide.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = state.guides?.map((guide: IGuide) => {
					if (guide?.id === action?.payload?.guideId) {
						const date = guide?.date ? [...guide?.date, action.payload] : [action.payload]
						return {...guide, date}
					} else {
						return guide
					}
				}) as IGuide[]
			}
			state.status = "succesed";
		})
		builder.addCase(createDateForGuide.rejected, (state) => {
			state.status = "failed";
		})
		// update date
		builder.addCase(updateDateById.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(updateDateById.fulfilled, (state: IGuidesState, action) => {
			if (action.payload) {
				state.guides = state.guides?.map((guide: IGuide) => {
					if (guide?.id === action?.payload?.guideId) {
						const date = guide?.date?.map((date: IDate) => {
							if (date?.id === action?.payload?.id) {
								return action?.payload
							} else {
								return date
							}
						})
						return {...guide, date}
					} else {
						return guide
					}
				}) as IGuide[]
			}
			state.status = "succesed";
		})
		builder.addCase(updateDateById.rejected, (state) => {
			state.status = "failed";
		})
  },
})

// Action creators are generated for each case reducer function
export const guidesActions: any = {
  ...guidesSlice.actions,
  getAllGuides,
	getAllGuidesBySearch,
	getGuidesById,
	createGuide,
	updateGuide,
	deleteGuideById,
	createDateForGuide,
	updateDateById,
}

export default guidesSlice.reducer
