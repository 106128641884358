import { createSlice } from '@reduxjs/toolkit'
import { IOrdersState } from 'types/orders';

import {
	getAllOrders,
	getAllOrdersBySearch
} from 'store/actions/OrdersActions'


const initialState: IOrdersState = {
	orders: [],
	order: null,
	count: null,
	status: "loading",
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
	reducers: {
		clearOrder: (state) => {
      state.order = initialState.order
    },
		setOrder: (state, action) => {
      state.order = action.payload
    },
	},
	extraReducers: (builder) => {
		// get all orders
		builder.addCase(getAllOrders.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllOrders.fulfilled, (state, action) => {
			if (action.payload) {
				state.orders = action.payload.orders;
				state.count = action.payload.count;
			}
			state.status = "succesed";
		})
		builder.addCase(getAllOrders.rejected, (state) => {
			state.orders = initialState.orders
			state.count = initialState.count
			state.status = "failed";
		})
		// search orders
		builder.addCase(getAllOrdersBySearch.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllOrdersBySearch.fulfilled, (state, action) => {
			if (action.payload) {
				state.orders = action.payload.orders;
				state.count = action.payload.count;
			}
			state.status = "succesed";
		})
		builder.addCase(getAllOrdersBySearch.rejected, (state) => {
			state.orders = initialState.orders
			state.count = initialState.count
			state.status = "failed";
		})
	}
})

// Action creators are generated for each case reducer function
export const ordersActions: any = {
  ...ordersSlice.actions,
	getAllOrders,
	getAllOrdersBySearch,
}

export default ordersSlice.reducer
