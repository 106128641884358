import { RouterProvider } from "react-router-dom";
import {router} from 'routes';
import { ToastContainer } from 'react-toastify'
import { ruRU } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from "@mui/system";

import moment from 'moment';
import 'moment/locale/ru';
// import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme(
  {},
  ruRU,
);

function App() {
	moment.locale('ru');

  return (
		<Box sx={{ width: "100%", height: "100vh", display: "flex" }}>
			<ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
			/>
			<ThemeProvider theme={theme}>
      	<RouterProvider router={router} />
			</ThemeProvider>
		</Box>
  );
}

export default App;
