import { combineReducers } from 'redux'
import authReducer from './Auth'
import toursReducer from './Tours'
import guidesReducer from './Guides'
import usersReducer from './Users'
import ordersReducer from './Orders'


const rootReducer = combineReducers({
	auth: authReducer,
	tours: toursReducer,
	guides: guidesReducer,
	users: usersReducer,
	orders: ordersReducer,
})

export default rootReducer
