import { IOrder } from 'types/orders';
import {$api} from './api';

export interface QueryParams {
  limit: number;
  offset?: number;
  search?: string;
}

export default class OrdersService {
	static async getAllOrders(params: QueryParams): Promise<{data: {orders: IOrder[], count: number}}> {
		return $api.get(`order/?${params?.limit ? `limit=${params.limit}` : ''}${params?.offset ? `&offset=${params?.offset}`: ''}`);
	}

	static async getAllOrdersBySearch(search: string): Promise<{data: {orders: IOrder[], count: number}}> {
		return $api.get(`order/?search=${search}`);
	}

}
