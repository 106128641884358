import { createSlice } from '@reduxjs/toolkit'
import { IUser, IUsersState } from 'types/users';
import {
	getAllUsers,
	getAllUsersBySearch,
	getUserById,
	createUser,
	updateUserById,
	deleteUser
} from 'store/actions/UsersActions'


const initialState: IUsersState = {
	users: [],
	user: null,
	count: null,
	status: "loading",
};

export const usersSlice = createSlice({
  name: 'guides',
  initialState,
	reducers: {
		clearUser: (state) => {
      state.user = initialState.user
    },
		setUser: (state, action) => {
      state.user = action.payload
    },
	},
	extraReducers: (builder) => {
		// get all users
		builder.addCase(getAllUsers.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllUsers.fulfilled, (state, action) => {
			if (action.payload) {
				state.users = action.payload.users;
				state.count = action.payload.count;
			}
			state.status = "succesed";
		})
		builder.addCase(getAllUsers.rejected, (state) => {
			state.users = initialState.users
			state.count = initialState.count
			state.status = "failed";
		})
		// search user
		builder.addCase(getAllUsersBySearch.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllUsersBySearch.fulfilled, (state, action) => {
			if (action.payload) {
				state.users = action.payload.users;
				state.count = action.payload.count;
			}
			state.status = "succesed";
		})
		builder.addCase(getAllUsersBySearch.rejected, (state) => {
			state.users = initialState.users
			state.count = initialState.count
			state.status = "failed";
		})
		// get user by id
		builder.addCase(getUserById.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getUserById.fulfilled, (state, action) => {
			if (action.payload) {
				state.user = action.payload;
			}
			state.status = "succesed";
		})
		builder.addCase(getUserById.rejected, (state) => {
			state.user = initialState.user
			state.status = "failed";
		})
		// create user
		builder.addCase(createUser.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createUser.fulfilled, (state, action) => {
			if (action.payload) {
				state.users = state.users ? [...state.users, action.payload] : [action.payload]
			}
			state.status = "succesed";
		})
		builder.addCase(createUser.rejected, (state) => {
			state.status = "failed";
		})
		// update user
		builder.addCase(updateUserById.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(updateUserById.fulfilled, (state, action) => {
			if (action.payload) {
				state.users = state.users?.map((user: IUser) => {
					if (user?.id === action?.payload?.id) {
						return action.payload
					} else {
						return user
					}
				}) as IUser[]
			}
			state.status = "succesed";
		})
		builder.addCase(updateUserById.rejected, (state) => {
			state.status = "failed";
		})
		// delete user
		builder.addCase(deleteUser.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteUser.fulfilled, (state, action) => {
			if (action.payload) {
				state.user = initialState.user
				state.users = state.users?.filter((user: IUser) =>
					user?.id !== action?.payload
				) as IUser[]
			}
			state.status = "succesed";
		})
		builder.addCase(deleteUser.rejected, (state) => {
			state.status = "failed";
		})
	}
})

// Action creators are generated for each case reducer function
export const usersActions: any = {
  ...usersSlice.actions,
	getAllUsers,
	getAllUsersBySearch,
	getUserById,
	createUser,
	updateUserById,
	deleteUser
}

export default usersSlice.reducer
