import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import GuidesService from 'services/GuidesService';
import { IDate, IGuide } from 'types/guides';


export const getAllGuides = createAsyncThunk(
  "guides/getAllGuides",
	async () => {
		try {
			const { data } = await GuidesService.getAllGuides()
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка получения гидов`)
		}
  }
);

export const getAllGuidesBySearch = createAsyncThunk(
  "guides/getAllGuidesBySearch",
	async (search: string) => {
		try {
			const { data } = await GuidesService.getAllGuidesBySearch(search)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка поиска гида`)
		}
  }
);

export const getGuidesById = createAsyncThunk(
  "guides/getGuidesById",
	async (id: string) => {
		try {
			const { data } = await GuidesService.getGuidesById(id)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка получения гида`)
		}
  }
);

export const createGuide = createAsyncThunk(
  "guides/createGuide",
	async (params: IGuide) => {
		try {
			const { data } = await GuidesService.createGuide(params)
			console.log(data);
			toast.success(`Гид создан`)
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка создания гида`)
		}
  }
);

export const updateGuide = createAsyncThunk(
  "guides/updateGuide",
	async (params: IGuide) => {
		try {
			const { data } = await GuidesService.updateGuide(params)
			console.log(data);
			toast.success(`Гид обновлён`)
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка обновления гида`)
		}
  }
);

export const createDateForGuide = createAsyncThunk(
  "guides/createDateForGuide",
	async (params: IDate) => {
		try {
			const { data } = await GuidesService.createDateForGuide(params)
			toast.success(`Дата добавлена`)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка создания даты для гида`)
		}
  }
);

export const updateDateById = createAsyncThunk(
  "guides/updateDateById",
	async (params: IDate) => {
		try {
			const { data } = await GuidesService.updateDateById(params)
			toast.success(`Дата обновлена`)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка обновления даты для гида`)
		}
  }
);

export const deleteGuideById = createAsyncThunk(
  "guides/deleteGuideById",
	async (id: string) => {
		try {
			const res = await GuidesService.deleteGuideById(id)
			console.log({ res });
			toast.success(`Гид удалён`)
			return id;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка удаления гида`)
		}
  }
);