import { Typography, Box } from "@mui/material";
const Logo = require("assets/Logopng.png");

const SidebarLogo = () => {
  return (
    <Box
      sx={{
        mt: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "180px",
      }}
    >
      <Box
        component="img"
        sx={{
          height: 20,
          width: 45,
          mr: 1,
          ml: 2,
        }}
        alt="Logo"
        src={Logo}
      />
      <Typography
        sx={{
          color: "#1976D2DE",
          fontSize: 12,
          lineHeight: 1,
          textTransform: "uppercase",
        }}
      >
        Панель администратора
      </Typography>
    </Box>
  );
};

export default SidebarLogo;
