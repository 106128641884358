
import { IAuthData, IRegisterData, IAdminData } from 'types/auth';
import {$api} from './api';

export default class AuthService {
	static async login(data: IAuthData): Promise<{data: {admin: IAdminData}}> {
		return $api.post('login/admin/', data);
	}

	static async registration(data: IRegisterData): Promise<{data: IAdminData}> {
		return $api.post('register/admin/', data);
	}


}
