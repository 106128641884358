import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import UsersService, { QueryParams } from 'services/UsersService';
import { IUser } from 'types/users';


export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
	async (params: QueryParams) => {
		try {
			const { data } = await UsersService.getAllUsers(params)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка получения пользователей`)
		}
  }
);

export const getAllUsersBySearch = createAsyncThunk(
  "users/getAllUsersBySearch",
	async (search: string) => {
		try {
			const { data } = await UsersService.getAllUsersBySearch(search)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка поиска пользователя`)
		}
  }
);

export const getUserById = createAsyncThunk(
  "users/getUserById",
	async (id: string) => {
		try {
			const { data } = await UsersService.getUserById(id)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка получения пользователя`)
		}
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
	async (params: IUser) => {
		try {
			const { data } = await UsersService.createUser(params)
			console.log(data);
			toast.success(`Пользователь создан`)
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка создания пользователя`)
		}
  }
);

export const updateUserById = createAsyncThunk(
  "users/updateUserById",
	async (params: IUser) => {
		try {
			const { data } = await UsersService.updateUserById(params)
			console.log(data);
			toast.success(`Пользователь обновлён`)
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка обновления пользователя`)
		}
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
	async (id: string) => {
		try {
			const { data } = await UsersService.deleteUser(id)
			toast.success(`Пользователь удалён`)
			console.log(data);
			return id;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка удаления пользователя`)
		}
  }
);
