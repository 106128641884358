import { authActions } from './reducers/Auth'
import { toursActions } from './reducers/Tours'
import { guidesActions } from './reducers/Guides'
import { usersActions } from './reducers/Users'
import { ordersActions } from './reducers/Orders'


const useActions = {
	...authActions,
	...toursActions,
	...guidesActions,
	...usersActions,
	...ordersActions,
}

export default useActions
