import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import AuthService from 'services/AuthService';

import { IAuthData, IRegisterData } from 'types/auth'

export const fetchAuth = createAsyncThunk(
  "auth/fetchAuth",
	async (loginData: IAuthData) => {
		try {
			const { data } = await AuthService.login(loginData)
			console.log(data);
			toast.success(`С возвращением, ${data?.admin?.name}!`)
			return data.admin;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка авторизации`)
		}
  }
);

export const fetchRegister = createAsyncThunk(
  "auth/fetchRegister",
	async (params: IRegisterData) => {
		try {
			const { data } = await AuthService.registration(params)
			console.log(data);
			toast.success('Добро пожаловать в систему')
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка регистрации`)
		}
  }
);