import { FC, ReactNode } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HikingIcon from "@mui/icons-material/Hiking";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarLogo from './SidebarLogo';
import LogoutIcon from '@mui/icons-material/Logout';
import { useActions } from 'hooks/useActions';


export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface IProps {
	open: boolean
	setOpen: Function
}

interface IMenuItem {
	title: string
	link: string
	icon: ReactNode
}

const menuItems: IMenuItem[] = [
	{title: 'Гиды', link: '/guides', icon: <HikingIcon />},
	{title: 'Пользователи', link: '/users', icon: <PeopleAltIcon />},
	{title: 'Маршруты', link: '/tours', icon: <LocationOnIcon />},
	{title: 'Заказы', link: '/orders', icon: <ShoppingCartIcon />},
]
const Sidebar: FC<IProps> = ({open, setOpen}) => {
  const handleDrawer = () => {
    setOpen((prev: boolean) => !prev);
	};

	const navigate = useNavigate();
	const location = useLocation();
	const {logOut} = useActions()

	const handleLogout = (): void => {
    if (window.confirm("Вы действительно хотите выйти?")) {
      logOut()
      navigate("/");
      localStorage.removeItem("token");
    }
    return;
  };

	return (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
        </DrawerHeader>
        <Divider />
			{open && <SidebarLogo />}
        <List>
          {menuItems?.map((el: IMenuItem) => (
            <ListItem key={el.title} disablePadding onClick={()=> navigate(el.link)}>
            <ListItemButton selected={el.link === location.pathname}>
              <ListItemIcon>
                {el?.icon}
              </ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </ListItem>
          ))}
        </List>
        <Divider />
        <List>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={handleLogout}>
              <ListItemButton>
                <ListItemIcon >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={'Выход'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
	)
}

export default Sidebar