import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import OrdersService, { QueryParams } from 'services/OrdersService';


export const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
	async (params: QueryParams) => {
		try {
			const { data } = await OrdersService.getAllOrders(params)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка получения заказов`)
		}
  }
);

export const getAllOrdersBySearch = createAsyncThunk(
  "orders/getAllOrdersBySearch",
	async (search: string) => {
		try {
			const { data } = await OrdersService.getAllOrdersBySearch(search)
			console.log(data);
			return data;
		} catch (error) {
			console.log({ error });
			toast.error(`Ошибка поиска заказа`)
		}
  }
);
