import {$api} from './api';
import { IUser } from 'types/users';

export interface QueryParams {
  limit: number;
  offset?: number;
  search?: string;
}

export default class UsersService {
	static async getAllUsers(params: QueryParams): Promise<{data: {users: IUser[], count: number}}> {
		return $api.get(`users/?${params?.limit ? `limit=${params.limit}` : ''}${params?.offset ? `&offset=${params?.offset}`: ''}`);
	}

	static async getAllUsersBySearch(search: string): Promise<{data: {users: IUser[], count: number}}> {
		return $api.get(`users/?search=${search}`);
	}

	static async getUserById(id: string): Promise<{data: IUser}> {
		return $api.get(`users/${id}`);
	}

	static async createUser(data: IUser): Promise<{data: IUser}> {
		return $api.post(`users/create-admin`, data);
	}

	static async updateUserById(data: IUser): Promise<{data: IUser}> {
		return $api.put(`users/${data.id}/update`, data);
	}

	static async deleteUser(id: string): Promise<any> {
		return $api.delete(`users/${id}/delete`);
	}

}
