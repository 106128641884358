import {FC, useEffect, useState} from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar, { drawerWidth } from 'components/Sidebar/'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useActions } from 'hooks/useActions';
import jwtDecode from 'jwt-decode';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open, isAuth }: { theme: any, open: boolean, isAuth: boolean }) => ({
    // flexGrow: 1,
    // height: `calc(99vh - ${theme.mixins.toolbar.minHeight}px)`,
		overflow: 'hidden',
		marginLeft: !isAuth ?  0 : open ? drawerWidth : `calc(${theme.spacing(8)} + 1px)`,
		transition: theme.transitions.create('marginLeft', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
  })
)

const Layout: FC = () => {
	const [open, setOpen] = useState<boolean>(true);
	const theme = useTheme()
	const location = useLocation()
	const { isAuth } = useSelector((state: RootState) => state.auth)
	const { setIsAuth } = useActions()
	const navigate = useNavigate()

	const handleExit = (): void => {
		setIsAuth(false)
		navigate('/')
	}

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token && token !== 'undefined') {
      const currentTime = Date.now() / 1000
      const decodedOldToken: any = jwtDecode(token)
      if (decodedOldToken?.exp < currentTime) {
        localStorage.removeItem('token')
        handleExit()
			} else {
				setIsAuth(true)
				if (location.pathname === '/') {
					navigate('/tours')
				}
			}
    } else {
      handleExit()
    }
  }, [])

	return (
		<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
			<CssBaseline />
			{isAuth && <Sidebar open={open} setOpen={setOpen} />}
			<Main theme={theme} open={open} isAuth={isAuth}>
				<Outlet />
			</Main>
		</Box>
	)
}

export default Layout