import { IDate, IGuide } from 'types/guides';
import {$api} from './api';
import { ITour } from 'types/tours';

export default class GuidesService {
	static async getAllGuides(): Promise<{data: IGuide[]}> {
		return $api.get('guides/');
	}

	static async getAllGuidesBySearch(search: string): Promise<{data: IGuide[]}> {
		return $api.get(`guides/?search=${search}`);
	}

	static async getGuidesById(id: string): Promise<{data: {guide: IGuide, tours: ITour[]}}> {
		return $api.get(`guides/${id}/`);
	}

	static async createGuide(data: IGuide): Promise<{data: IGuide}> {
		return $api.post(`guides/create`, data);
	}

	static async updateGuide(data: IGuide): Promise<{data: IGuide}> {
		return $api.put(`guides/${data.id}/update`, data);
	}

	static async createDateForGuide(data: IDate): Promise<{data: IDate}> {
		return $api.post(`tours/add_date`, data);
	}

	static async updateDateById(data: IDate): Promise<{data: IDate}> {
		return $api.put(`tours/${data.id}/update_date`, data);
	}

	static async deleteGuideById(id: string): Promise<any> {
		return $api.delete(`guides/${id}/delete`);
	}


}
