import { ICheckpoint, ICreateTourDto, ITour, ITourResponse } from 'types/tours';
import {$api} from './api';

export default class ToursService {
	static async getAllTours(): Promise<{data: ITourResponse}> {
		return $api.get('tours/');
	}

	static async getToursById(id: string): Promise<{data: ITour}> {
		return $api.get(`tours/${id}`);
	}

	static async createTour(data: ICreateTourDto): Promise<{data: ITour}> {
		return $api.post(`tours/create`, data);
	}

	static async updateTour(data: ITour): Promise<{data: ITour}> {
		return $api.put(`tours/${data.id}/update`, data);
	}

	static async deleteTour(id: string): Promise<any> {
		return $api.delete(`tours/${id}/delete`);
	}

	//==================Checkpoints==================

	static async getAllCheckPoints(): Promise<{data: ICheckpoint[]}> {
		return $api.get(`checkpoint`);
	}

	static async getCheckpointById(id: string): Promise<{data: ICheckpoint}> {
		return $api.get(`checkpoint/${id}`);
	}

	static async getCheckpointByTourId(tourId: string): Promise<{data: ICheckpoint}> {
		return $api.get(`checkpoint/${tourId}/by_tour`);
	}

	static async createCheckPoint(data: ICheckpoint): Promise<{data: ICheckpoint}> {
		return $api.post(`checkpoint/create`, data);
	}

	static async updateCheckPointById(data: ICheckpoint): Promise<{data: ICheckpoint}> {
		return $api.put(`checkpoint/${data.id}/update`, data);
	}

	static async deleteCheckPointById(id: string): Promise<{data: ICheckpoint}> {
		return $api.delete(`checkpoint/${id}/delete`);
	}
}
