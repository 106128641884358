import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import ToursService from 'services/ToursService';

import { ICheckpoint, ICreateTourDto, ITour } from 'types/tours';

//=================Tour=======================

export const getAllTours = createAsyncThunk(
  "tours/getAllTours",
	async () => {
		try {
			const { data } = await ToursService.getAllTours()
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка получения туров`)
		}
  }
);

export const getToursById = createAsyncThunk(
  "tours/getToursById",
	async (id: string) => {
		try {
			const { data } = await ToursService.getToursById(id)
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка получения тура`)
		}
  }
);

export const createTour = createAsyncThunk(
  "tours/createTour",
	async (params: ICreateTourDto) => {
		try {
			const { data } = await ToursService.createTour(params)
			toast.success(`Тур успешно создан`)
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка создания тура`)
		}
  }
);

export const updateTour = createAsyncThunk(
  "tours/updateTour",
	async (params: ITour) => {
		try {
			const { data } = await ToursService.updateTour(params)
			toast.success(`Тур успешно отредактирован`)
			return data;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка редактирования тура`)
		}
  }
);

export const deleteTour = createAsyncThunk(
  "tours/deleteTour",
	async (params: string) => {
		try {
			await ToursService.deleteTour(params)
			toast.success(`Тур успешно удалён`)
			return params;
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка удаления тура`)
		}
  }
);

//===================Checkpoints=================


export const createCheckPoint = createAsyncThunk(
  "tours/createCheckPoint",
	async (params: ICheckpoint) => {
		try {
			const { data } = await ToursService.createCheckPoint(params)
			toast.success(`Чекпоинт создан`)
			return data
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка создания чекпоинта`)
		}
  }
);

export const updateCheckPointById = createAsyncThunk(
  "tours/updateCheckPointById",
	async (params: ICheckpoint) => {
		try {
			const { data } = await ToursService.updateCheckPointById(params)
			toast.success(`Чекпоинт успешно отредактирован`)
			return data
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка редактирования чекпоинта`)
		}
  }
);

export const deleteCheckPointById = createAsyncThunk(
  "tours/deleteCheckPointById",
	async (params: ICheckpoint) => {
		try {
			await ToursService.deleteCheckPointById(params.id!)
			toast.success(`Чекпоинт успешно удалён`)
			return params
		} catch (error) {
			console.log({ error })
			toast.error(`Ошибка удаления чекпоинта`)
		}
  }
);