import { createSlice } from '@reduxjs/toolkit'
import {
	getAllTours,
	getToursById,
	createTour,
	updateTour,
	deleteTour,
	deleteCheckPointById,
	updateCheckPointById,
	createCheckPoint
} from 'store/actions/ToursActions'
import { ICheckpoint, ITour, IToursInitialState } from 'types/tours';


const initialState: IToursInitialState = {
	tours: [],
	tour: null,
	count: 0,
	status: "loading",
};

export const toursSlice = createSlice({
  name: 'auth',
  initialState,
	reducers: {
		clearTour: (state) => {
      state.tour = initialState.tour
    },
		setTour: (state, action) => {
      state.tour = action.payload
    },
	},
	extraReducers: (builder) => {
    // FETCH tours
    builder.addCase(getAllTours.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(getAllTours.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = action.payload.tours;
				state.count = action.payload.count;
			}
      state.status = "succesed";
    })
    builder.addCase(getAllTours.rejected, (state) => {
      console.log("Ошибка получения туров");
      state.tours = initialState.tours
      state.count = initialState.count
      state.status = "failed";
    })
    // FETCH tour
    builder.addCase(getToursById.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(getToursById.fulfilled, (state, action) => {
			if (action.payload) {
				state.tour = action.payload
			}
      state.status = "succesed";
    })
    builder.addCase(getToursById.rejected, (state) => {
      console.log("Ошибка получения тура");
      state.tour = initialState.tour
      state.status = "failed";
    })
    // Create tour
    builder.addCase(createTour.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(createTour.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state.tours.length ? [...state.tours, action.payload] : [action.payload]
			}
      state.status = "succesed";
    })
    builder.addCase(createTour.rejected, (state) => {
      console.log("Ошибка создания тура");
      state.status = "failed";
    })
    // update tour
    builder.addCase(updateTour.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(updateTour.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state.tours?.map((tour: ITour) => {
					if (tour?.id === action?.payload?.id) {
						return action.payload
					} else {
						return tour
					}
				}) as ITour[]
			}
      state.status = "succesed";
    })
    builder.addCase(updateTour.rejected, (state) => {
      console.log("Ошибка редактирования тура");
      state.status = "failed";
    })
    // delete tour
    builder.addCase(deleteTour.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(deleteTour.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state.tours?.filter((tour: ITour) => tour.id !== action?.payload) as ITour[]
			}
      state.status = "succesed";
    })
    builder.addCase(deleteTour.rejected, (state) => {
      console.log("Ошибка удаления тура");
      state.status = "failed";
    })
    // create checkpoint
    builder.addCase(createCheckPoint.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(createCheckPoint.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state?.tours?.map((tour: ITour) => {
					//находим нужный тур по чекпоинту
					if (tour?.id === action.payload?.tourId) {
						//добавляем чекпоинт в тур
						const checkpoints = tour?.checkpoints ? [...tour.checkpoints, action.payload] as ICheckpoint[] : [action.payload] as ICheckpoint[]
						return {...tour, checkpoints}
					} else {
						return tour
					}
				}) as ITour[]
			}
      state.status = "succesed";
    })
    builder.addCase(createCheckPoint.rejected, (state) => {
      console.log("Ошибка создания чекпоинта");
      state.status = "failed";
    })
    // update checkpoint
    builder.addCase(updateCheckPointById.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(updateCheckPointById.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state?.tours?.map((tour: ITour) => {
					//находим нужный тур по чекпоинту
					if (tour?.id === action.payload?.tourId) {
						tour?.checkpoints?.map((checkpoint: ICheckpoint) => {
							//находим нужный чекпоинт
							if (checkpoint.id === action?.payload?.id) {
								//заменяем его
								return action.payload
							} else {
								return checkpoint
							}
						})
					} else {
						return tour
					}
				}) as ITour[]
			}
      state.status = "succesed";
    })
    builder.addCase(updateCheckPointById.rejected, (state) => {
      console.log("Ошибка редактирования чекпоинта");
      state.status = "failed";
    })
    // delete checkpoint
    builder.addCase(deleteCheckPointById.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(deleteCheckPointById.fulfilled, (state, action) => {
			if (action.payload) {
				state.tours = state?.tours?.map((tour: ITour) => {
					//находим нужный тур по id
					if (tour?.id === action.payload?.tourId) {
						//возвращаем все, кроме удалённого
						tour?.checkpoints?.filter((checkpoint: ICheckpoint) =>
							checkpoint?.id !== action?.payload?.id
						)
					} else {
						return tour
					}
				}) as ITour[]
			}
      state.status = "succesed";
    })
    builder.addCase(deleteCheckPointById.rejected, (state) => {
      console.log("Ошибка удаления чекпоинта");
      state.status = "failed";
    })
  },
})

// Action creators are generated for each case reducer function
export const toursActions: any = {
  ...toursSlice.actions,
  getAllTours,
	getToursById,
	createTour,
	updateTour,
	deleteTour,
	createCheckPoint,
	updateCheckPointById,
	deleteCheckPointById
}

export default toursSlice.reducer
