import { createSlice } from '@reduxjs/toolkit'
import {fetchAuth, fetchRegister} from 'store/actions/AuthActions'
import { IAuthInitialState } from 'types/auth'



const initialState: IAuthInitialState = {
  userData: {
		id: '',
		email: '',
		name: '',
		phone: '',
		role: '',
		isActive: true,
		token: null,
		createdAt: '',
		updatedAt: ''
  },
	status: "loading",
	isAuth: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.userData = initialState.userData
      state.isAuth = initialState.isAuth
    },
    setAdmin: (state, action) => {
      state.userData = action.payload;
    },
    setToken: (state, action) => {
      state.userData.token = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
  },
	extraReducers: (builder) => {
    // FETCH AUTH
    builder.addCase(fetchAuth.pending, (state) => {
      state.status = "loading";
    })
		builder.addCase(fetchAuth.fulfilled, (state: IAuthInitialState, action) => {
			if (action.payload) {
				state.userData = action.payload;
				state.isAuth = true
				localStorage.setItem("token", action.payload.token!)
			}
      state.status = "succesed";
    })
    builder.addCase(fetchAuth.rejected, (state) => {
      console.log("Ошибка авторизации");
      alert("Неверный логин или пароль");
      state.userData = initialState.userData
      state.isAuth = initialState.isAuth
      state.status = "failed";
    })
      // FETCH REGISTER
      builder.addCase(fetchRegister.pending, (state) => {
        state.status = "loading";
      })
      builder.addCase(fetchRegister.fulfilled, (state, action) => {
				if (action.payload) {
					state.userData = action.payload;
					state.isAuth = true
					localStorage.setItem("token", action.payload.token!)
				}
        state.status = "succesed";
      })
      builder.addCase(fetchRegister.rejected, (state) => {
        console.log("Ошибка регистрации");
        state.userData = initialState.userData;
        state.isAuth = false
      })
  },
})

// Action creators are generated for each case reducer function
export const authActions: any = {
  ...authSlice.actions,
  fetchRegister,
  fetchAuth,
}

export default authSlice.reducer
