import Loadable from "components/Loadable";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from 'layout/';

const Login = Loadable(lazy(() => import('pages/Login')));
const Tours = Loadable(lazy(() => import('pages/Tours')));
const Tour = Loadable(lazy(() => import('pages/Tour')));
const Guides = Loadable(lazy(() => import('pages/Guides')));
const Guide = Loadable(lazy(() => import('pages/Guide')));
const Users = Loadable(lazy(() => import('pages/Users')));
const Orders = Loadable(lazy(() => import('pages/Orders')));

export const router = createBrowserRouter([
  {
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <Login />,
			},
			{
				path: 'tours',
				element: <Tours />,
			},
			{
				path: 'tours/:id',
				element: <Tour />,
			},
			{
				path: 'guides',
				element: <Guides />,
			},
			{
				path: 'guides/:id',
				element: <Guide />,
			},
			{
				path: 'users',
				element: <Users />,
			},
			{
				path: 'orders',
				element: <Orders />,
			},
		]
	},
]);
